import React, { useState, useEffect } from 'react';
import { Card, CardContent } from '@mui/material';
import {
    ResponsiveContainer,
    BarChart,
    XAxis,
    YAxis,
    CartesianGrid,
    Bar,
    Tooltip as RechartToolTip,
    Legend,
} from 'recharts';
import dataProvider from '../dataProvider';
import { InfoOutlined } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import tokenManager from '../tokenManager';

const dateFormatter = (date) => new Date(date).toLocaleDateString();

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#000000',
      color: '#FFFFFF',
      fontSize: theme.typography.pxToRem(14),
      maxWidth: 360,
    },
  }));

const renderColorfulLegendText = (value: string, entry: any) => { return <span style={{ color: "#828282", fontSize: "12px" }}>{value}</span>; };

export default function App(props) {

    const [data, setData] = useState<any | []>([]);

    useEffect(() => {
        // TODO the auth0Token in sessionStorage is set just before this is called in authProvider. figure out a better way to listen for change
        // checking for sessionStorage change doesnt work, something like
        // window.addEventListener("storage", e => storageChange(e));
        // return () => {
        //     window.removeEventListener('storage', e => storageChange(e));
        // };
        setTimeout(()=>{ // migrated functionality of "getApiData" to here to fix build warning
            if(tokenManager.getToken()) {
                dataProvider.get(`kaizenovate/provider/1.0.0/clinic_visits?month=${props.month}`).then(result => {
                    setData(result.data);
                });
            }
        }, 100)
    }, [props.month]);

    return (
        <Card>
            <CardContent className='card-header'>
                Weekly Clinic Visit Adherence
                <HtmlTooltip
                    title={
                    <React.Fragment>
                        <Typography color="inherit">Weekly Clinic Visit Adherence</Typography>
                        <br />
                        {"Compare the total number of appointments scheduled versus the total number of appointments actually attended."}
                        <br /><br />
                        {"Over time, your practice should notice an increase in weekly clinic visit adherence as a result of patient interaction with the app."}
                    </React.Fragment>
                    }
                >
                    <InfoOutlined fontSize='small'/>
                </HtmlTooltip>
            </CardContent>
            <CardContent>
                <div style={{ width: '100%', height: 400 }}>
                    <ResponsiveContainer>
                        <BarChart data={data} barGap={20}>
                            <defs>
                                <linearGradient
                                    id="colorUv"
                                    x1="0"
                                    y1="0"
                                    x2="0"
                                    y2="1"
                                >
                                    <stop
                                        offset="5%"
                                        stopColor="#8884d8"
                                        stopOpacity={0.8}
                                    />
                                    <stop
                                        offset="95%"
                                        stopColor="#8884d8"
                                        stopOpacity={0}
                                    />
                                </linearGradient>
                            </defs>
                            <XAxis
                                dataKey="date"
                                tickFormatter={(label) =>
                                    dateFormatter(label)
                                }
                            />
                            <YAxis dataKey="Scheduled - App User" label={{ value: 'Visits', angle: -90, position: 'insideLeft' }} />
                            <CartesianGrid strokeDasharray="3 3" />
                            <RechartToolTip
                                cursor={{ strokeDasharray: '3 3' }}
                                labelFormatter={(label) =>
                                    dateFormatter(label).replace('_', ' ')
                                }
                            />
                            <Legend
                                layout="horizontal"
                                verticalAlign="bottom"
                                align="center"
                                formatter={renderColorfulLegendText}
                                iconType={'circle'}
                            />
                            <Bar
                                dataKey="Actual - App user"
                                fill="#6FCF97"
                                stackId="b"
                                barSize={22}
                            />
                            <Bar
                                dataKey="Scheduled - App User"
                                fill="#C1ECD3"
                                stackId="b"
                                barSize={22}
                            />
                            <Bar
                                dataKey="Actual - Non-App User"
                                fill="#A3A3A3"
                                stackId="a"
                                barSize={22}
                            />
                            <Bar
                                dataKey="Scheduled - Non-App User"
                                fill="#DADADA"
                                stackId="a"
                                barSize={22}
                            />
                        </BarChart>
                    </ResponsiveContainer>
                </div>
            </CardContent>
        </Card>
    );
};