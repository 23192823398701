import { Grid, Typography } from '@mui/material';
import {
    AutocompleteInput,
    AutocompleteArrayInput,
    BooleanInput,
    CheckboxGroupInput,
    Create,
    FormDataConsumer,
    RadioButtonGroupInput,
    ReferenceArrayInput,
    ReferenceInput,
    required,
    SimpleForm,
    useGetOne,
    useNotify,
    useRedirect,
    Confirm,
    useRefresh,
    useRecordContext,
    TextInput,
} from 'react-admin';
import { RichTextInput } from 'ra-input-rich-text';
import { useWatch, useFormContext } from 'react-hook-form';
import '../../scss/pages/EnhancedMessaging.scss';
import QueryBuilder from '../../components/enhancedmessaging/QueryBuilder';
import CustomRichTextToolbar from '../../components/enhancedmessaging/CustomRichTextToolbar';
import ScheduleOrSendToolbar from '../../components/enhancedmessaging/ScheduleOrSendToolbar';
import TransformBroadcastData from '../../components/enhancedmessaging/TransformBroadcastData';
import { useEffect, useState } from 'react';
import { InfoOutlined } from '@mui/icons-material';
import { HtmlTooltip } from '../../components/smstransparency/HtmlTooltip';
import { ConfirmSpecialText } from '../../components/smstransparency/ConfirmSpecialText';
import { CustomCleanText } from '../../components/smstransparency/CustomCleanText';
import { SMSStatusDisplayField } from '../../components/smstransparency/SMSStatusDisplayField';
import { creditProcessing } from '../../components/smstransparency/helpers/creditProcessing';
import { fetchPatientEstimates } from '../../components/smstransparency/helpers/fetchPatientEstimates';
import { TemplateEstimatesField } from '../../components/smstransparency/TemplateEstimatesField';

const transform = (data: any, sendNow: boolean) => {
    // default submit transform - not in use due to ScheduleOrSendToolbar override
    const transformResult = TransformBroadcastData(data, sendNow);
    return transformResult
}

const gridProps = {
    container: true,
    rowSpacing: 1,
    mb: 1,
    columnSpacing: 1
}

const RenderMessageTemplate = ({setTemplateContent, setEstimatedCredits,
    setSpecialTextNotify,
    setEstimatedCreditsRange,
    setIsMultiCredit,
    estimatedCredits,
    estimatedCreditsRange,
    specialTextNotify,
    notGSMCompliantOnLoad,
    setNotGSMCompliantOnLoad}) => {
    const formValues = useWatch();
    const channels = formValues.channels;
    // RenderMessageTemplate is only called when formValues.template exists
    const record = useGetOne(`messagetemplates`, { id: formValues.template });

    const formatMessage = (message: string) => {
        // repalce newlines with <p> tags
        let sms = message ? message.replaceAll('\n', '</p><p>') : '';
        if (!sms.startsWith('<p>') && !sms.endsWith('</p>')) sms = '<p>' + sms + '</p>';
        // no more than one consecutive empty paragraph
        sms = sms.replace(/(<p><\/p>){2,}/g, '<p></p>');
        setTemplateContent(sms)
        return sms;
    }

    if (channels && record.data) {
        const sms_html = channels.includes('sms') && record.data.sms_message && (
            <>
                <p>SMS template</p>
                <div
                    className='broadcast-textarea'
                    dangerouslySetInnerHTML={{ __html: formatMessage(record.data.sms_message) }}
                />
                 <TemplateEstimatesField templateText={record.data.sms_message}
                                setEstimatedCredits={setEstimatedCredits}
                                setSpecialTextNotify={setSpecialTextNotify}
                                setEstimatedCreditsRange={setEstimatedCreditsRange}
                                setIsMultiCredit={setIsMultiCredit}
                                estimatedCredits={estimatedCredits}
                                estimatedCreditsRange={estimatedCreditsRange}
                                specialTextNotify={specialTextNotify}
                                notGSMCompliantOnLoad={undefined}
                                setNotGSMCompliantOnLoad={undefined}
                                clearable={false} // pre-existing template not clearable
                                handleCleanPress={undefined} // pre-existing template not clearable
                                />
            </>
        );

        const email_html = channels.includes("email") &&
            record.data.email_message && record.data.name ? (
            <>
                <p >Email Subject</p>
                <div
                    id="email-subject"
                    className="broadcast-textarea"
                    style={{ minHeight: "35px" }}
                    dangerouslySetInnerHTML={{
                        __html: formatMessage(record.data.email_subject || record.data.name),// prefers email_subject over name of template
                    }}
                />

                <p>Email template</p>
                <div
                    className="broadcast-textarea"
                    dangerouslySetInnerHTML={{
                        __html: formatMessage(record.data.email_message),
                    }}
                />
            </>
        ) : null;
        return (
            <div className="broadcast-textarea-wrapper">
                {sms_html}
                {email_html}
            </div>
        );
    }
    return <div></div>;
};

const ConfirmElectronicMail = () => {
    const [open, setOpen] = useState(false);
    const [hasOpened, setHasOpened] = useState(false);

    const formValues = useWatch();
    const channels = formValues.channels;

    if (channels && channels.includes('email') && !hasOpened) {
        setOpen(true);
        setHasOpened(true);
    }

    return (
        <Confirm
            isOpen={open}
            title='Electronic Mail'
            className='broadcast-confirm-modal'
            content={
                <p>
                    Because you have enabled email for this message, we have added formatting options, but keep in mind formatting is not available for Text Messages and App Notifications.
                </p>
            }
            onClose={() => setOpen(false)}
            onConfirm={() => setOpen(false)}
            confirm='Continue'
        />
    );
};


const validateAdvancedQuery = (advancedQuery: any) => {
    if (!advancedQuery) return 'Required';
    try { // if valid JSON string
        if (advancedQuery) {
            const query = JSON.parse(advancedQuery);
            return validateQuery(query); // validate if it has a rule besides the default one
        } else {
            return 'Invalid JSON';
        }
    } catch (e) {
        return 'Invalid JSON';
    }
}

const validateQuery = (query: any) => {
    if (query && query.rules && query.rules.length > 0) {
        // invalid if the only rule is the default rule
        const rules = query.rules.filter((rule: any) => rule.field !== 'default');
        if (rules.length > 0) {
            const ruleEmptyValue = rules.filter((rule: any) => !rule.value);
            if (ruleEmptyValue.length > 0) return 'Rule value is required';
            else return undefined;
        } else {
            return 'Query needs at least one rule';
        }
    } else {
        return 'Query needs at least one rule';
    }
}

// ConfirmAdvancedQuery can't be conditionally rendered because it would reset 'hasOpened'
const ConfirmAdvancedQuery = () => {
    const [open, setOpen] = useState(false);
    const [hasOpened, setHasOpened] = useState(false);

    const formValues = useWatch();
    const formContext = useFormContext();
    const advancedMode = formValues.advanced_query_enabled;

    if (advancedMode && !hasOpened) {
        setOpen(true);
        setHasOpened(true);
    }

    let query = formValues.selection_criteria;

    if (advancedMode) {
        if (query && typeof query === 'object') {
            if (formValues.selection_criteria_with_error) {
                formContext.setValue('selection_criteria', formValues.selection_criteria_with_error)
                formContext.setValue('selection_criteria_with_error', undefined);
            } else {
                query = JSON.stringify(query, null, 4);
                formContext.setValue('selection_criteria', query)
            }
        }
    } else {
        if (query && typeof query === 'string') {
            try {
                query = JSON.parse(query);
                formContext.setValue('selection_criteria', query)
            } catch (e) {
                console.error('Invalid JSON');
                formContext.setValue('selection_criteria', JSON.stringify({
                    combinator: 'and',
                    rules: [{
                        field: 'default',
                        id: '99999999-9999-9999-9999-999999999999',
                    }],
                }, null, 4));
                if (!formValues.selection_criteria_with_error) {
                    // set a "backup" of the invalid JSON so the user can edit it
                    formContext.setValue('selection_criteria_with_error', query);
                }
            }
        }
    }

    return (
        <Confirm
            isOpen={open}
            title='Warning! Advanced Feature!'
            className='broadcast-confirm-modal confirm-query'
            content={<>
                <p>
                    Selections entered here may not work as expected. Use only if you are familiar with the JSON format and the query structure.
                </p>
                <p>
                    If you undo this selection and edit the basic criteria, the advanced criteria will be lost.
                </p>
            </>}
            onClose={() => {
                formContext.setValue('advanced_query_enabled', false);
                setOpen(false)
            }}
            onConfirm={() => setOpen(false)}
            confirm='Continue'
        />
    );
};


const MessageBroadcastCreate = () => {
    const [specialTextNotify, setSpecialTextNotify] = useState(false);
    const [estimatedCredits, setEstimatedCredits] = useState(1);
    const [estimatedCreditsRange, setEstimatedCreditsRange] = useState("");
    // const [averageCredits, setAverageCredits] = useState(1);
    const [practiceId] = useState("");
    const [selectionCriteria, setSelectionCriteria] = useState({}); // will be json need to convert pre fetch
    const [advancedQuerySelected, setAdvancedQuerySelected] = useState(false);
    const [templateContent, setTemplateContent] = useState("")
    const [criteriaQueryChosen, setCriteriaQueryChosen] = useState(false);
    const [estimatedPatients, setEstimatedPatients] = useState(0);
    const [isMultiCredit, setIsMultiCredit] = useState(false);  // needed for multi credit setting conditions in RenderTemplate
    const [includesSMSChannel, setIncludesSMSChannel] = useState(false);

    sessionStorage.setItem("scheduled", "false");
    const notify = useNotify();
    const redirect = useRedirect();
    const refresh = useRefresh();
   
    // trigger fetchPatientEstimates
    useEffect(() => {
        const practiceId = sessionStorage.getItem("selectedPractice");

        const debounceFetch = setTimeout(async () => {
            if (criteriaQueryChosen && practiceId && selectionCriteria) {
                if (selectionCriteria && advancedQuerySelected) {
                    // when selection criteria state changes validate
                    // validate function should prevent fetching if advanced json is bad
                    let isValid = validateAdvancedQuery(selectionCriteria)
                    // undefined is valid advanced query
                    if(isValid === undefined){
                        let results = await fetchPatientEstimates(practiceId, selectionCriteria, advancedQuerySelected);
                        if (typeof results === 'number') {
                            setEstimatedPatients(results);
                        }   
                    }
                } else {
                    // if not an advanced query
                    let results = await fetchPatientEstimates(practiceId, selectionCriteria, advancedQuerySelected);
                    if (typeof results === 'number') {
                        setEstimatedPatients(results);
                    }
                }
            }
        }, 1500); // 1.5 seconds of state changes, you can change state multiple times before timeout and it will only trigger once

        return () => clearTimeout(debounceFetch);
    }, [criteriaQueryChosen, practiceId, selectionCriteria, advancedQuerySelected]);

    const onSuccess = (data: any) => {
        if (sessionStorage.getItem('scheduled') === 'false') {
            notify('Message sent', { type: 'info' });
            redirect('/messagelogs');
        } else {
            notify('Message scheduled', { type: 'info' });
            redirect('/messageautomations');
        }
        setTimeout(refresh, 1000);
    }

    const CustomOption = () => {
        let item = useRecordContext()

        return <span>{item.first} {item.last} ({item.emr_patient_id})</span>
    }

    const EstimatedAudienceSize = ({ estimatedPatients }) => {

        const estimatedAudienceMessage = `Estimated Audience Size:  ${estimatedPatients}`;

        return <div style={{ marginTop: "20px", color: estimatedPatients > 100 || estimatedPatients === 0 ? 'red' : "inherit" }}>{estimatedAudienceMessage} {estimatedPatients === 0 ? (<span> <a href='https://kaizenovate.zendesk.com/hc/en-us/articles/33359214464667-EMBODIconnect-Broadcast-Audience-Size' target='_blank'>Support Article</a></span>) : ""}</div>;

    }

    return (
        <Create
            className='broadcast-create'
            sx={{ maxWidth: '1020px' }}
            transform={(data: any) => transform(data, false)}
            mutationOptions={{ onSuccess }}
        >
            <SimpleForm
                sx={{ pt: 0, pb: 0 }}
                className='broadcast'
                // some hardcoded values for now
                toolbar={<ScheduleOrSendToolbar includesSMSChannel={includesSMSChannel} estimatedAudience={estimatedPatients} estimatedCredits={estimatedCredits} estimatedCreditsRange={estimatedCreditsRange} templateContent={templateContent} />}
            >
                <h1>Broadcast Messaging</h1>
                <Grid {...gridProps}>
                    <p className='broadcast-label'>1. Select which patients:</p>
                    <Grid item xs={12}>
                        <RadioButtonGroupInput
                            className='broadcast-radio-group'
                            source='group_type'
                            defaultValue='user_ids'
                            choices={[
                                { id: 'user_ids', name: 'Send to specific patients only' },
                                {
                                    id: 'custom_query',
                                    name: 'Send to all patients that match a criteria',
                                },
                            ]}
                            onChange={(e) => {
                                if (e.target.value === 'custom_query') {
                                    setCriteriaQueryChosen(true);
                                } else {
                                    setCriteriaQueryChosen(false);
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <ConfirmAdvancedQuery />
                        <FormDataConsumer>
                            {({ formData }) => {
                                // console.log("formData", formData)
                                if ( formData.advanced_query_enabled) {
                                    setAdvancedQuerySelected(true);
                                } else {
                                    setAdvancedQuerySelected(false);
                                }
                                if (formData.selection_criteria){

                                    setSelectionCriteria(formData.selection_criteria)
                                }

                                if (formData?.group_type === 'user_ids') {
                                    if (formData?.user_ids?.length > 0) {
                                        setEstimatedPatients(formData.user_ids.length);
                                    }
                                    return (
                                        <ReferenceArrayInput
                                            source='user_ids'
                                            reference='users'
                                            enableGetChoices={(q) => {
                                                if (q && q.name && q.name.length >= 3) {
                                                    return true;
                                                } else {
                                                    return false;
                                                }
                                            }}
                                        >
                                            <AutocompleteArrayInput className='hello'
                                                // this is what goes into the input array after selection
                                                inputText={(choice) => `${choice.first} ${choice.last}`}
                                                // needs to be a custom component for the drop down items
                                                optionText={<CustomOption />}
                                                filterToQuery={(value) => ({ name: value })}
                                                label='Select users...'
                                                validate={[required()]}
                                            />
                                        </ReferenceArrayInput>
                                    );
                                } else {
                                    return (
                                        <div className='queryBuilder-wrapper'>
                                            <BooleanInput
                                                source='advanced_query_enabled'
                                                label='Advanced input'
                                                sx={{ '& label': { marginLeft: 'auto', marginTop: '-54px', marginBottom: '8px' }, '& p': { display: 'none' } }}
                                            />
                                            {formData.advanced_query_enabled ? (
                                                <TextInput
                                                    source='selection_criteria'
                                                    fullWidth
                                                    multiline
                                                    className='textinput-multiline'
                                                    validate={() => validateAdvancedQuery(formData.selection_criteria)}
                                                />
                                            ) : (<>
                                                <QueryBuilder />
                                                <EstimatedAudienceSize estimatedPatients={estimatedPatients} />
                                                <TextInput
                                                    source='selection_criteria'
                                                    validate={() => validateQuery(formData.selection_criteria)}
                                                    // hide everything but the error message
                                                    sx={{ marginTop: '0', '& label': { display: 'none' }, '& div': { display: 'none' } }}
                                                    // set to QueryBuilder default value in the string format we use for selection_criteria
                                                    defaultValue={
                                                        JSON.stringify({
                                                            combinator: 'and',
                                                            rules: [{
                                                                field: 'default',
                                                                id: '99999999-9999-9999-9999-999999999999',
                                                            }],
                                                        }, null, 4)
                                                    }
                                                />
                                            </>)}
                                        </div>
                                    );
                                }
                            }}
                        </FormDataConsumer>
                    </Grid>
                </Grid>

                <Grid {...gridProps}>
                    <p className='broadcast-label'>2. Select your channels:</p>
                    <Grid item xs={12}>
                    <FormDataConsumer>
                    {({ formData }) => {
                        if(formData.channels && formData.channels.includes('sms')){ 
                            setIncludesSMSChannel(true)
                        } else {
                            setIncludesSMSChannel(false)
                        }
                        return <CheckboxGroupInput
                            source='channels'
                            choices={[
                                { id: 'sms', name: 'Text Message' },
                                { id: 'email', name: 'Electronic Mail' },
                            ]}
                            defaultValue={['sms']}
                            validate={[required()]}
                        /> }}
                        </FormDataConsumer>
                        <ConfirmElectronicMail />
                    </Grid>
                </Grid>

                <Grid {...gridProps} className='broadcast-richtext'>
                    <p className='broadcast-label'>3. Select your message:</p>
                    <Grid item xs={12}>
                        <BooleanInput label='Use existing template' source='use_template' />
                    </Grid>
                    <FormDataConsumer>
                        {({ formData }) => {
                            // In this case we are calling for credit processing and setting state
                            creditProcessing(formData, specialTextNotify, setSpecialTextNotify, estimatedCredits, setEstimatedCredits, estimatedCreditsRange, setEstimatedCreditsRange, templateContent)
                            return formData.use_template ? (
                                <>
                                    <Grid item xs={12}>
                                        <ReferenceInput
                                            source='template'
                                            reference='messagetemplates'
                                            filter={{ is_broadcast: false, is_provider_notification: false, event_type: 'General' }}
                                            sort={{ field: 'name', order: 'ASC' }}
                                        >
                                            <AutocompleteInput
                                                optionText='name'
                                                label='Select a template...'
                                                filterToQuery={(value) => ({ name: value })}
                                                validate={[required()]}
                                            />
                                        </ReferenceInput>
                                    </Grid>
                                    {formData.template && (
                                        <Grid item xs={12} className='broadcast-multiline'>
                                            <RenderMessageTemplate
                                                setTemplateContent={setTemplateContent}
                                                setEstimatedCredits={setEstimatedCredits}
                                                setSpecialTextNotify={setSpecialTextNotify}
                                                setEstimatedCreditsRange={setEstimatedCreditsRange}
                                                setIsMultiCredit={setIsMultiCredit}
                                                estimatedCredits={estimatedCredits}
                                                estimatedCreditsRange={estimatedCreditsRange}
                                                specialTextNotify={specialTextNotify}
                                                notGSMCompliantOnLoad={undefined}
                                                setNotGSMCompliantOnLoad={undefined} />
                                        </Grid>
                                    )}
                                </>
                            ) : (
                                <>
                                    {formData.channels && formData.channels.length > 0 && (
                                        <>
                                            {formData.channels.includes('email') ? <Grid item xs={12}>
                                                <p >Email Subject</p>
                                                <RichTextInput
                                                    id="email-subject"
                                                    source="email_subject"
                                                    fullWidth
                                                    helperText="Enter an email subject - dynamic text supported"
                                                    // defaultValue={formData? formData.name: ""} // not showing up when use existing template is picked after Electronic Mail box
                                                    validate={[required()]}
                                                    toolbar={<CustomRichTextToolbar richTextMode={false} />}
                                                />
                                            </Grid> : null}
                                            <Grid item xs={12}>
                                                <RichTextInput
                                                    source='custom_message'
                                                    fullWidth
                                                    validate={[required()]}
                                                    toolbar={
                                                        <CustomRichTextToolbar
                                                            richTextMode={formData.channels.includes('email')}
                                                        />
                                                    }
                                                />
                                                {formData.channels.includes('sms') ? <FormDataConsumer>
                                                    {({ formData }) => {
                                                            let { parsedMessage, hasDynamicText, displayMin, displayMax, htmlDecode, hasTrailingNewLine, gsm7compliant, messageLength } = creditProcessing(formData, specialTextNotify, setSpecialTextNotify, estimatedCredits, setEstimatedCredits, estimatedCreditsRange, setEstimatedCreditsRange, templateContent)

                                                        return (
                                                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                                    <SMSStatusDisplayField
                                                                        parsedMessage={parsedMessage}
                                                                        hasDynamicText={hasDynamicText}
                                                                        displayMin={displayMin}
                                                                        displayMax={displayMax}
                                                                        htmlDecode={htmlDecode}
                                                                        hasTrailingNewLine={hasTrailingNewLine}
                                                                        gsm7compliant={gsm7compliant}
                                                                        estimatedCreditsRange={estimatedCreditsRange}
                                                                        estimatedCredits={estimatedCredits} />
                                                                    {hasDynamicText && (
                                                                        <HtmlTooltip style={{ marginLeft: "5px" }}
                                                                            title={
                                                                                <>
                                                                                    <Typography color="black">
                                                                                        We are unable to calculate the exact number of credits because you are using Dynamic Text. Dynamic text can create different lengths for each message. Therefore, credit usage can vary.
                                                                                    </Typography>
                                                                                </>
                                                                            }
                                                                        >
                                                                            <InfoOutlined fontSize='small' />
                                                                        </HtmlTooltip>
                                                                    )}
                                                                </div>
                                                                {specialTextNotify && includesSMSChannel ? <ConfirmSpecialText formField={"custom_message"}/> : null}
                                                                {messageLength > 0 ? (
                                                                    <div style={{ display: 'inline-block' }}>
                                                                        <CustomCleanText formField={"custom_message"} setNotGSMCompliantOnLoad={undefined} handleCleanPress={undefined} />
                                                                    </div>
                                                                ) : null}
                                                            </div>
                                                        )}}
                                                </FormDataConsumer > : null}
                                            </Grid>
                                        </>
                                    )}
                                </>
                            )}
                        }
                    </FormDataConsumer>
                </Grid>
            </SimpleForm>
        </Create>
    );
};

export default MessageBroadcastCreate;
