import React, { useState, useEffect } from 'react';
import { 
    ResponsiveContainer,
    BarChart, 
    Bar, 
    XAxis, 
    YAxis, 
    CartesianGrid, 
    Tooltip as RechartToolTip,
    Legend 
} from 'recharts';
import { Card, CardContent } from '@mui/material';
import dataProvider from '../dataProvider';
import { InfoOutlined } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import tokenManager from '../tokenManager';

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#000000',
      color: '#FFFFFF',
      fontSize: theme.typography.pxToRem(14),
      maxWidth: 360,
    },
  }));

const renderColorfulLegendText = (value: string, entry: any) => { return <span style={{ color: "#828282", fontSize: "12px" }}>{value}</span>; };


export default function App(props) {

    const [data, setData] = useState<any | []>([]);

    useEffect(() => {
        // TODO the auth0Token in sessionStorage is set just before this is called in authProvider. figure out a better way to listen for change
        // checking for sessionStorage change doesnt work, something like
        // window.addEventListener("storage", e => storageChange(e));
        // return () => {
        //     window.removeEventListener('storage', e => storageChange(e));
        // };
        setTimeout(()=>{
            if(tokenManager.getToken()) {
                dataProvider.get(`kaizenovate/provider/1.0.0/patient_discharges?month=${props.month}`).then(result => {
                    setData(result.data);
                });
            }
        }, 100)
    }, [props.month]);

    return (
        <Card>
            <CardContent className='card-header'>
                Patient Discharges
                <HtmlTooltip
                    title={
                    <React.Fragment>
                        <Typography color="inherit">Patient Discharges</Typography>
                        <br />
                        {"Doctor-Dishcarge:"}
                        <li>{"Doctor decides patient has completed care"}</li>
                        <br />
                        {"Self-Discharge:"}
                        <li>{"Patient says they don’t want to come back "}</li>
                        <li>{"Patient wants to cancel all future appointments"}</li>
                        <li>{"Patient misses 3 consecutive appointments"}</li>
                    </React.Fragment>
                    }
                >
                    <InfoOutlined fontSize='small'/>
                </HtmlTooltip>
            </CardContent>
            <CardContent>
                <div style={{ margin: 'auto', height: 400 }}>
                    <ResponsiveContainer>
                    <BarChart 
                    width={600} 
                    height={300} 
                    data={data} 
                    layout="vertical"
                    margin={{top: 5, right: 30, left: 40, bottom: 5}}
                    >
                        <XAxis type="number"/>
                        <YAxis type="category" dataKey="name" />
                        <CartesianGrid strokeDasharray="3 3"/>
                        <RechartToolTip/>
                        <Legend
                            layout="horizontal"
                            verticalAlign="bottom"
                            align="center"
                            formatter={renderColorfulLegendText}
                            iconType={'circle'}
                        />
                        <Bar dataKey="App Users" stackId="a" fill="#6FCF97" barSize={56} />
                        <Bar dataKey="Non-App Users" stackId="a" fill="#A3A3A3" barSize={56} />
                    </BarChart>
                    </ResponsiveContainer>
                </div>
            </CardContent>
        </Card>
    );
}