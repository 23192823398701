import React, { useState, useEffect } from 'react';
import {
    ResponsiveContainer,
    Legend,
    FunnelChart,
    Funnel,
    Tooltip as RechartToolTip,
    LabelList
} from 'recharts';
import { Card, CardContent } from '@mui/material';
import dataProvider from '../dataProvider';
import { InfoOutlined } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import tokenManager from '../tokenManager';

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#000000',
      color: '#FFFFFF',
      fontSize: theme.typography.pxToRem(14),
      maxWidth: 360,
    },
  }));

const renderColorfulLegendText = (value: string, entry: any) => { return <span style={{ color: "#828282", fontSize: "12px" }}>{value}</span>; };


export default function App(props) {
    
    const [data, setData] = useState<any | []>([]);

    useEffect(() => {
        // TODO the auth0Token in sessionStorage is set just before this is called in authProvider. figure out a better way to listen for change
        // checking for sessionStorage change doesnt work, something like
        // window.addEventListener("storage", e => storageChange(e));
        // return () => {
        //     window.removeEventListener('storage', e => storageChange(e));
        // };
        setTimeout(()=>{ // migrated functionality of "getApiData" to here to fix build warning
            if(tokenManager.getToken()) {
                dataProvider.get(`kaizenovate/provider/1.0.0/app_engagements?month=${props.month}`).then(result => {
                    setData(result.data);
                });
            }
        }, 100)
    }, [props.month]);

    return (
        <Card>
            <CardContent className='card-header'>
                App Engagement
                <HtmlTooltip
                    title={
                    <React.Fragment>
                        <Typography color="inherit">App Engagement</Typography>
                        <br />
                        {"All Patients:"}<li>{"Total from Current Patient Mix"}</li>
                        <br />
                        {"Downloaded App:"}<li>{"Count of patients who have downloaded app"}</li>
                        <br />
                        {"Created Account:"}<li>{"Count of patients who have created an account, enabled EHR connection, and set a goal"}</li>
                        <br />
                        {"Has Score:"}<li>{"Count of patients who are actively engaging with the app and have had a non-zero Accountability Score in the selected time period"}</li>
                    </React.Fragment>
                    }
                >
                    <InfoOutlined fontSize='small'/>
                </HtmlTooltip>
            </CardContent>
            <CardContent>
                <div style={{ margin: 'auto', height: 400 }}>
                    <ResponsiveContainer>
                        <FunnelChart width={730} height={250}>
                            <RechartToolTip />
                            <Legend
                                layout="horizontal"
                                verticalAlign="bottom"
                                align="center"
                                formatter={renderColorfulLegendText}
                                iconType={'circle'}
                            />
                            <Funnel
                                name="All"
                                dataKey="value"
                                data={data}
                                isAnimationActive
                                fill="#A3A3A3"
                            >
                                <LabelList
                                    position="inside"
                                    fill="#000"
                                    stroke="none"
                                    dataKey="label"
                                />
                            </Funnel>
                            <Funnel
                                dataKey="value"
                                name="Downloaded App"
                                fill="#7B61FF"
                            />
                            <Funnel
                                dataKey="value"
                                name="Created Account"
                                fill="#007AFF"
                            />
                            <Funnel
                                dataKey="value"
                                name="Has Score"
                                fill="#6FCF97"
                            />
                        </FunnelChart>
                    </ResponsiveContainer>
                </div>
            </CardContent>
        </Card>
    );
}
