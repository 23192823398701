import { useEffect, useState } from "react";
import {Button as MUIButton } from '@mui/material';

import {
    Button,
    Confirm,
    DateInput,
    fetchUtils,
    SaveButton,
    TimeInput,
    Toolbar,
} from "react-admin";
import SendIcon from '@mui/icons-material/Send';
import ScheduleIcon from '@mui/icons-material/Schedule';
import SendTestMessage from "./SendTestMessage";
import TransformBroadcastData from "./TransformBroadcastData";
import TimeZoneNotice from "./TimeZoneNotice";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from "@mui/material";
import tokenManager from "../../tokenManager";
import { ConfirmSMSCredits } from "../smstransparency/ConfirmSMSCredits";


const fetchCreditUsage = async (practiceId: string) => {

    const apiUrl = (window.location.hostname === "localhost") ? 'http://localhost:1337' : '';
    const accessToken = tokenManager.getToken();

    if (!practiceId) {
        console.error("Practice ID is missing");
        return;
    }

    const options: any = {}
    options['credentials'] = 'include'
    options['user'] = {
        authenticated: true,
        token: `Bearer ${accessToken}`,
    }


    const url = `${apiUrl}/kaizenovate/provider/1.0.0/get-sms-credit-usage?practice_id=${practiceId}`;

    const result = await fetchUtils.fetchJson(url, options)
    // console.log(result);


    if (result.status < 200 || result.status >= 300) {
        console.error("An error occurred while trying to sync patient DX codes")
    }

    return result.json;


}

const ScheduleOrSendToolbar = ({includesSMSChannel, estimatedAudience, estimatedCredits, estimatedCreditsRange, templateContent  }) => {
    const [open, setOpen] = useState(false);
    const [creditNotify, setCreditNotify] = useState(false);
    const [sendNowPressed, setSendNowPressed] = useState(false);
    const [credits, setCredits] = useState({ planCredits: 0, usedCredits: 0 });

    useEffect(() => {
        const practiceId = sessionStorage.getItem("selectedPractice"); // Replace "default-practice-id" with an appropriate fallback if needed
        if (practiceId) {
            fetchCreditUsage(practiceId).then((data) => {
                // console.log("Fetched credit usage data:", data);
                if (data) {
                    setCredits({
                        planCredits: data.credit_limit,
                        usedCredits: data.credits_used,
                    });
                }
            }).catch((error) => {
                console.error("Error fetching credit usage:", error);
            });
        }
    }, []);

    return (
        <Toolbar className='broadcast-save-toolbar'>
            {/* in the case where we do have sms as an included channel render modal (not as complicated for schedule as that already has a modal) */}
            {creditNotify && includesSMSChannel ? <ConfirmSMSCredits
                setScheduleOpen={setOpen}
                setSendNowPressed={setSendNowPressed}
                sendNowPressed={sendNowPressed}
                setCreditNotify={setCreditNotify}
                credits={credits}
                estimatedAudience={estimatedAudience}
                estimatedCredits={estimatedCredits}
                estimatedCreditsRange={estimatedCreditsRange}
               />
                : null}
            {/* trigger schedule window */}
            <Button
                label='Schedule message'
                onClick={() => {

                    setCreditNotify(true)
                    setOpen(true)
                }}
                startIcon={<ScheduleIcon />}
                variant='contained'
                className='broadcast-schedule-trigger'
            />
            <Confirm
                isOpen={open}
                title='Schedule Message'
                className='broadcast-schedule-modal'
                content={<>
                    <div className='broadcast-schedule-fields'>
                        <DateInput source='date' />
                        <TimeInput source='time' />
                        {/* <SelectInput source='time_zone'
                            validate={[required()]} // this + defaultValue remove the empty option
                            defaultValue='New_York' // update to practice timezone
                            choices={[
                                { id: 'New_York', name: 'New York (EDT)' },
                                { id: 'Chicago', name: 'Chicago (CDT)' },
                                { id: 'Denver', name: 'Denver (MDT)' },
                                { id: 'Phoenix', name: 'Phoenix (MST)' },
                                { id: 'Los_Angeles', name: 'Los Angeles (PDT)' },
                                { id: 'Anchorage', name: 'Anchorage (AKDT)' },
                                { id: 'Honolulu', name: 'Honolulu (HST)' },
                            ]} 
                        /> */}
                    </div>
                    <div style={{ width: '48%', paddingLeft: '52%', marginTop: '-20px' }}>
                        <TimeZoneNotice />
                    </div>
                </>}
                onClose={() => {
                    setCreditNotify(false)
                    setOpen(false)
                }}
                onConfirm={() => {
                    sessionStorage.setItem("scheduled", "true")
                    const submitBtn = document.querySelector('.broadcast-schedule-submit') as HTMLElement
                    setOpen(false)
                    submitBtn.click()
                }}
                confirm='Send'
            />

            {/* hidden submit for schedule */}
            <SaveButton className='broadcast-schedule-submit' label='Schedule message' />

            {/* send message now */}
            <SaveButton
                className='broadcase-send-submit'
                sx={{ display: 'none' }}
                label='Send message now'
                icon={<SendIcon />}
                type='button'
                transform={(data: any) => TransformBroadcastData(data, true)}
            />
            <Button
                label='Send message now'
                startIcon={<SendIcon />}
                variant='contained'
                className='broadcast-schedule-trigger'
                onClick={() => {
                    // adds bypass to just send message on click if sms autho modal doesn't need to show up as it's not one of the selected channels
                    if (!includesSMSChannel) {
                        const submitBtn = document.querySelector('.broadcase-send-submit') as HTMLElement | null;
                        if (submitBtn) {
                            submitBtn.click();
                        }}
                    sessionStorage.setItem("scheduled", "false")

                    setCreditNotify(true)
                    setSendNowPressed(true)
                }}
            />
            <SendTestMessage origin='broadcast' />
        </Toolbar>
    )
}

export default ScheduleOrSendToolbar;